import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgWhyMarsCover from "../../assets/images/mission/why-mars/why-mars-cover.png"
import imgWhyMarsSectionOne from "../../assets/images/home/home-section-2.png"
import imgMarsSurface from "../../assets/images/home/mars-surface.png"

const WhyMarsPage = () => (
  <Layout>
    <SEO title="Why Mars" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgWhyMarsCover} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-200px", position: "relative"}}>
          <img src={imgWhyMarsSectionOne} className="uk-visible@s" style={{position: "absolute", top: "0", left: "50%", width: "50%", height: "auto", zIndex: "50"}} alt=""/>
        <div className="large-container mt6-ns pb6">
            <div className="standard-container standard-bg">
              <div className="flex flex-column flex-row-ns">
                <div className="w-100 w-50-ns pr5-ns pb5-ns z-index-10">
                <div>
                  <h2 className="relative">
                    Why Mars
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  The Red Planet has captured human imagination for centuries. After decades of exploration we consider Mars to be the closest habitable planet with proximity to Earth. While there are other planets that are even closer to Earth, none of them have features like Mars has, to potentially sustain life, like:                  </p>
                  
                  <ul className="uk-list uk-list-bullet text-dim">
                    <li>Its soil contains water</li>
                    <li>It has a tolerable climate</li>
                    <li>It receives enough sunlight to power solar panels</li>
                    <li>Its gravity is sufficient for the human body to adapt to</li>
                    <li>It has various seasons and its day and night breakup is almost equivalent to that of Earth’s </li>
                  </ul>
                  <p>
                  Beyond our search for life on Mars, it also offers us the opportunity to understand the origin and evolution of the climatic and geological process that has shaped its current state and if it is applicable to other planets in the solar system. Many of the missions sent to Mars have shown us that it was habitable in its past. Understanding the evolution of Mars’ surface and atmosphere could help us predict the future of our planet.
                  </p>
                </div>
              </div>
              <div className="w-100 w-50-ns">
                <img src={imgWhyMarsSectionOne} className="w-90 h-auto uk-hidden@s" alt=""/>
              </div>  
            </div>
           </div>
        </div>  
      </section>
    </main>
  </Layout>
)

export default WhyMarsPage
